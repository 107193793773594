
.ai-dubbing-audio {
  width: 100%;
  height: 15rem;
}

.ai-dubbing-video {
  width: 100%;
  height: 30rem;
}

.ai-dubbing-spinner {
  vertical-align: middle;
}

.ai-dubbing-workspace {
  min-height: 60.8rem;
  max-height: 60.8rem;
  overflow: scroll;
}

.ai-dubbing-workspace-fs {
  min-height: 48.8rem;
  max-height: 48.8rem;
  overflow: scroll;
}

.ai-dubbing-title {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.ai-dubbing-playing {
  background-color: darkseagreen;
}

.ai-dubbing-group {
  margin-bottom: 0.15rem;
}

.ai-dubbing-command {
  cursor: pointer;
  user-select: none;
}

.ai-dubbing-alert {
  margin-bottom: 0.08rem;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.ai-dubbing-button {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
